<template>
  <div class="report_container">
    <van-cell title="检查报告" icon="orders-o" is-link :border="false" @click="navCheckedList" />
    <van-cell title="检验报告" icon="description" is-link :border="false" @click="navInspectedList" />
    <!-- <van-cell title="心电图报告"
              icon="description"
              is-link
              @click="navECGDetail"
              :border="false" /> -->

    <van-cell title="放射报告" icon="description" is-link @click="navRadiateDetail" :border="false" />
    <van-cell title="病理报告" icon="description" is-link @click="navPathologyDetail" :border="false" />
  </div>
</template>

<script>
import { jsonPost, formPost } from "@/baseAPI";
import qs from "qs";
export default {
  name: "Report",
  data () {
    return {};
  },
  created () {
    if (window.localStorage.getItem("openId") === null || window.localStorage.getItem("openId") == '') {
      this.getCode();
    }
  },
  methods: {
    // 获取code
    getCode () {
      let local = window.location.href;
      let appid = "wxcdae494f7e9422e7";
      window.location.href = `https://open.weixin.qq.com/connect/oauth2/authorize?appid=${appid}&redirect_uri=${local
        }&response_type=code&scope=snsapi_base&state=123&#wechat_redirect`;
      // window.location.href = "https://open.weixin.qq.com/connect/oauth2/authorize?appid=wxcdae494f7e9422e7&redirect_uri=http://tssywx.tssrmyy.com&response_type=code&scope=snsapi_base&state=STATE#wechat_redirect"
      let str = window.location.href;
      let code = str.split("code=")[1].split("&")[0];
      this.code = code
      this.login(code);
    },
    //获取openId
    async login (code) {
      this.$toast.loading({
        duration: 0,
        message: "加载中...",
        forbidClick: true
      });
      let data = qs.stringify({
        code: code
      });
      const { data: res } = await formPost("/wxapp/login", data);
      this.$toast.clear();
      if (res.code === 0) {
        window.localStorage.setItem("openId", res.data.openid);
        // this.getUserInfo(res.data.accessToken, res.data.openid);
      } else {
        this.$toast.fail(res.msg);
      }
    },
    navCheckedList () {
      this.$router.push({
        path: "/report/list",
        query: {
          name: "检查报告"
        }
      });
    },
    navInspectedList () {
      this.$router.push({
        path: "/report/list",
        query: {
          name: "检验报告"
        }
      });
    },
    navRadiateDetail () {
      this.$router.push({
        path: "/report/list",
        query: {
          name: "放射报告"
        }
      });
    },
    navPathologyDetail () {
      this.$router.push({
        path: "/report/list",
        query: {
          name: "病理报告"
        }
      });
    },
    navECGDetail () {
      this.$router.push({
        path: "/report/list",
        query: {
          name: "心电图报告"
        }
      });
    },
  }
};
</script>

<style scope>
.report_container .van-cell {
  border-bottom: 2px solid #f5f5f5;
  padding: 30px;
}

.report_container .van-cell__title {
  font-size: 40px;
}

.report_container .van-cell__left-icon,
.van-cell__right-icon {
  font-size: 40px;
  color: #1989fa;
}
</style>